<template>
  <header
    class="z-40 shrink-0 w-full flex-none text-sm font-semibold leading-6 border-b border-accent-dark/30 backdrop-blur bg-accent-light/40"
  >
    <nav aria-label="Global" class="container">
      <div class="relative flex items-center">
        <router-link to="/" class="mr-auto flex-none text-accent-dark relative">
          <the-logo class="h-navbar" />
        </router-link>

        <div class="hidden lg:flex lg:items-center">
          <template v-for="link in links" :key="link.id">
            <router-link
              :to="link.to"
              class="block rounded-md py-2 px-4 text-sm text-accent-dark hover:text-white hover:bg-accent-dark cursor-pointer transition-colors"
              exact-active-class="!text-accent-light pointer-events-none"
            >
              {{ link.text }}
            </router-link>
          </template>
        </div>

        <burger-menu
          :links="links"
          class="lg:hidden !absolute xs:!relative top-1/2 -right-2 -mt-4 xs:mt-0"
        />
      </div>
    </nav>
  </header>
</template>

<script setup>
import TheLogo from './TheLogo.vue';
import BurgerMenu from './BurgerMenu.vue';

const links = [
  { text: 'Подія', to: '/' },
  { text: 'Купити квиток', to: '/book' }
];
</script>
