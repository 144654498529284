<template>
  <layout>
    <div class="container">
      <div class="sm:bg-white sm:border border-accent-dark/30 rounded-2xl sm:p-8 sm:flex items-stretch space-x-0 sm:space-x-8 my-4 sm:my-10">
        <div class="shrink-0 object-contain w-1/4 float-left mr-4 mb-4 sm:float-none sm:mr-0 sm:mb-0">
          <img src="/novoshow.jpg" class="rounded-xl" alt="">
        </div>
        <div class="grow xs:flex flex-col justify-between space-y-4">
          <div>
            <h1 class="font-black tracking-tight text-black text-xl md:text-2xl lg:text-5xl text-balance">
              Благодійне новорічне шоу «У країні дитячих мрій»
            </h1>

            <h2 class="font-bold tracking-tight text-[#8d867c] text-sm sm:text-md md:text-lg lg:text-2xl xl:text-3xl mt-4">
              17 грудня
              <span class="mx-1 md:mx-2 xl:mx-4">&bullet;</span>
              12:00
              <span class="mx-1 md:mx-2 xl:mx-4">&bullet;</span>
              Русанівська набережна, 12
            </h2>
          </div>

          <div class="space-y-4 clear-both xs:clear-none">
            <div class="flex space-x-2 md:space-x-4 sm:items-center">
              <div class="shrink-0 text-gray">
                <ph-calendar-dots class="size-4 md:size-8 mt-0.5 sm:mt-0" />
              </div>
              <div class="grow font-semibold text-xs md:text-xl">
                17 грудня 12:00
              </div>
            </div>

            <div class="flex space-x-2 md:space-x-4 sm:items-center">
              <div class="shrink-0 text-gray">
                <ph-map-pin class="size-4 md:size-8 mt-0.5 sm:mt-0" />
              </div>
              <div class="grow font-semibold text-xs md:text-xl">
                Київ, Русанівська набережна, 12
                <span class="mx-1 md:mx-2">&bullet;</span>
                Культурний кластер «Краків»
              </div>
            </div>

            <div class="flex space-x-2 md:space-x-4 sm:items-center4">
              <div class="shrink-0 text-gray">
                <ph-tag class="size-4 md:size-8 mt-0.5 sm:mt-0" />
              </div>
              <div class="grow font-semibold text-xs md:text-xl">
                200 - 1500 грн
              </div>
            </div>
          </div>

          <div class="text-[#8d867c] font-semibold text-xs md:text-base lg:text-lg xl:text-xl">
            Частину коштів з продажу квитків буде спрямовано на благодійність.
          </div>

          <div>
            <button class="btn-primary text-xl sm:text-2xl w-full xs:w-auto" @click="router.push('/book')">Купити квиток</button>
          </div>
        </div>
      </div>

      <div class="sm:bg-white sm:border border-accent-dark/30 rounded-2xl sm:p-8 my-10 space-y-4 sm:space-y-8 text-sm sm:text-base">
        <p>
          «У країні дитячих мрій» — це не просто вистава, а справжнє видовище, яке поєднує в собі дивовижні танцювальні виступи, приголомшливу акробатика, театральні та вокальні номери, а також інноваційні візуальні ефекти.
        </p>

        <p>
          На сцену вийдуть більше 400 талановитих учнів Новопечерської школи і вразять вас своїми талантами.
        </p>

        <p>
          Це чарівна історія про віру в свої мрії , надію та силу добра, яка нагадає глядачам, що дива трапляються, а мрії здійснюються якщо в них щиро вірити.
        </p>
      </div>
    </div>
  </layout>
</template>

<script setup>
import Layout from './Layout.vue';
import { PhCalendarDots, PhMapPin, PhTag } from '@phosphor-icons/vue';
import { useRouter } from 'vue-router';

const router = useRouter();
</script>
