<template>
  <div class="min-h-full flex flex-col">
    <div v-if="isLoaded" class="grow p-4 space-y-4">
      <ticket-item v-for="ticketId in booked" :key="ticketId" :ticket="tickets[ticketId]" @unbook="emit('unbook', ticketId)" />

      <div v-if="booked.length" class="flex items-center p-2 space-x-2 border border-accent-dark/30 rounded shadow bg-accent-light/10">
        <ph-hand-coins class="-my-2 size-8 xl:size-10 text-accent-dark/20" weight="thin" />

        <div class="grow text-accent-dark font-semibold">
          <div class="text-sm opacity-70 font-normal">
            Cервісний збір, 7%
          </div>
        </div>

        <div class="shrink-0 text-accent-dark font-semibold">
          {{ taxHr }}
          <span class="opacity-70 font-normal">грн</span>
        </div>

        <div>
          <div class="size-6 ml-2" />
        </div>
      </div>
    </div>

    <div class="srink-0">
      <div class="pt-4 px-4 pb-0.5 border-t border-accent-dark/30">
        <div class="flex justify-between">
          <div>{{ hrBookedLength }}</div>
          <div>{{ bookedPrice }}</div>
        </div>
      </div>

      <div class="px-4 pb-4 pt-3">
        <button class="btn-primary w-full" :disabled="!booked.length" @click="emit('checkout')">Замовити</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import TicketItem from './TicketItem.vue';
import { PhHandCoins } from '@phosphor-icons/vue';

const props = defineProps({
  tickets: {
    type: Object,
    default: () => ({})
  },
  booked: {
    type: Array,
    default: () => []
  },
  isLoaded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([ 'unbook', 'checkout' ]);

const hrBookedLength = computed(() => {
  const count = props.booked?.length || 0;

  if (!count) {
    return 'Oберіть місця';
  }

  if (count === 1) {
    return '1 квиток';
  }

  if (count > 1 && count < 5) {
    return `${count} квитки`;
  }

  return `${count} квитків`;
});

const bookedPrice = computed(() => {
  const price = props.booked.reduce((acc, id) => acc + (props.tickets[id]?.priceGroup?.priceInCents || 0), 0);
  return price ? `${price * 107 / 10000} грн` : null;
});

const taxHr = computed(() => {
  const price = props.booked.reduce((acc, id) => acc + (props.tickets[id]?.priceGroup?.priceInCents || 0), 0);
  return price ? `${price * 7 / 10000}` : null;
});
</script>
