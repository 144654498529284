<template>
  <div>
    <p class="opacity-60">Контакти: </p>
    <a
      v-for="link in links"
      :key="link.id"
      :href="link.href"
      class="hover:opacity-70 transition-opacity"
    >
      <span class="sr-only">{{ link.text }}</span>
      <component :is="link.icon" class="h-6 w-6" aria-hidden="true" />
    </a>
  </div>
</template>

<script setup>
import { PhEnvelope, PhTelegramLogo } from '@phosphor-icons/vue';

const links = [
  { id: 'email', text: 'Email', href: 'mailto:info@novoshow.site', icon: PhEnvelope },
  { id: 'telegram', text: 'Telegram', href: 'https://t.me/novoshow_tickets_support_bot', icon: PhTelegramLogo }
];
</script>
