<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 600" xml:space="preserve">
    <path
      fill="rgb(var(--color-accent-light))"
      d="M518.577,291.605l53.19-53.19l-35.709-35.709c-21.382,14.449-50.86,12.2-69.712-6.653 v0c-18.854-18.854-21.101-48.331-6.653-69.713l-35.71-35.709l-53.19,53.19l22.047,22.047l-14.117,14.117l-22.047-22.047 L152.502,362.114l35.7,35.699c21.386-14.491,50.886-12.259,69.765,6.62c18.879,18.88,21.111,48.379,6.619,69.765l35.699,35.699 L504.46,305.722l-22.048-22.047l14.117-14.117L518.577,291.605z M391.458,192.72l14.117-14.117l32.744,32.743l-14.117,14.117 L391.458,192.72z M469.679,270.941l-32.743-32.743l14.117-14.117l32.743,32.743L469.679,270.941z"
    />
    <path
      fill="rgb(var(--color-accent-dark))"
      d="M293.207,179.061L117.191,355.076L0,237.886l63.182-63.182l7.057,6.945 c13.659,13.44,35.792,13.351,49.341-0.198c13.549-13.549,13.638-35.683,0.198-49.341l-6.945-7.058l63.182-63.181L293.207,179.061z  M600,238.415L300.285,538.13l-63.182-63.183l6.945-7.057c13.439-13.658,13.35-35.793-0.198-49.341 c-13.548-13.548-35.682-13.637-49.341-0.199l-7.058,6.944l-63.182-63.181L423.984,62.4l63.174,63.173l-6.926,7.057 c-13.403,13.658-13.299,35.777,0.231,49.307v0c13.529,13.53,35.647,13.634,49.306,0.231l7.057-6.926L600,238.415z M482.413,283.675 l14.117-14.117l22.048,22.047l53.19-53.19l-35.709-35.709c-21.382,14.449-50.86,12.2-69.712-6.653v0 c-18.854-18.854-21.101-48.331-6.653-69.713l-35.71-35.709l-53.19,53.19l22.047,22.047l-14.117,14.117l-22.047-22.047 L152.502,362.114l35.7,35.699c21.386-14.491,50.886-12.259,69.765,6.62c18.879,18.88,21.111,48.379,6.619,69.765l35.699,35.699 L504.46,305.722L482.413,283.675z M405.575,178.604l-14.117,14.117l32.744,32.743l14.117-14.117L405.575,178.604z M436.936,238.197 l32.743,32.743l14.117-14.117l-32.743-32.743L436.936,238.197z"
    />
  </svg>
</template>
