<template>
  <div class="w-full h-full flex flex-col justify-center items-center text-accent-dark">
    <h1 class="text-2xl font-bold -mt-10 mb-4">Cтатус платежу</h1>

    <div v-if="currentState === STATE_NO_INVOICE" class="opacity-80 text-center">
      <p>Не можемо знайти ваш інвойс</p>
    </div>

    <div v-if="currentState === STATE_PENDING" class="opacity-80 text-center">
      <p>Очікуємо на підтвердження оплати</p>
    </div>

    <div v-if="currentState === STATE_SUCCESS" class="opacity-80 text-center">
      <p>Оплата успішно здійснена</p>
      <p>Квитки вислані на ваш Email</p>
    </div>

    <div v-if="currentState === STATE_FAILED" class="opacity-80 text-center">
      <p>Оплата не вдалася</p>
      <p>Спробуйте ще раз</p>
    </div>

    <div>
      <router-link to="/" class="btn-primary mt-8">На головну</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue';
import { useStorage, useFetch } from '@vueuse/core';

const lastInvoiceId = useStorage('lastInvoiceId', null);

const FETCH_TIMEOUT_MS = 1000;

const STATE_NO_INVOICE = 1;
const STATE_PENDING = 2;
const STATE_SUCCESS = 3;
const STATE_FAILED = 4;

const currentState = ref(lastInvoiceId.value ? STATE_PENDING : STATE_NO_INVOICE);

let timeout = null;
onBeforeUnmount(() => clearTimeout(timeout));

if (lastInvoiceId.value) {
  const { execute } = useFetch(`/api/purchase/${lastInvoiceId.value}/status/`, {
    afterFetch(ctx) {
      if (ctx.data === 'success') {
        clearTimeout(timeout);
        currentState.value = STATE_SUCCESS;
      } else if (ctx.data === 'failed') {
        clearTimeout(timeout);
        currentState.value = STATE_FAILED;
      } else {
        timeout = setTimeout(execute, FETCH_TIMEOUT_MS);
      }

      return ctx;
    }
  }).get().text();
}

</script>
