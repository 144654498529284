export default [
  '',
  '#FFF5CC',
  '#FFD699',
  '#FFFF99',
  '#CCFFCC',
  '#99FFFF',
  '#CC99FF',
  '#99CCFF',
  '#FFFFCC',
  '#CCFF99',
  '#99E6E6',
  '#CCCCFF'
];

// Next commented strings forces tailwind to use this colors
// 'text-[#FFF5CC]',
// 'text-[#FFD699]',
// 'text-[#FFFF99]',
// 'text-[#CCFFCC]',
// 'text-[#99FFFF]',
// 'text-[#CC99FF]',
// 'text-[#99CCFF]',
// 'text-[#FFFFCC]',
// 'text-[#CCFF99]',
// 'text-[#99E6E6]',
// 'text-[#CCCCFF]'

// 'bg-[#FFF5CC]',
// 'bg-[#FFD699]',
// 'bg-[#FFFF99]',
// 'bg-[#CCFFCC]',
// 'bg-[#99FFFF]',
// 'bg-[#CC99FF]',
// 'bg-[#99CCFF]',
// 'bg-[#FFFFCC]',
// 'bg-[#CCFF99]',
// 'bg-[#99E6E6]',
// 'bg-[#CCCCFF]'
