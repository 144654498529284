<template>
  <layout>
    <color-palette v-model="colorFilters" :price-groups />

    <seat-select :booked="booked" :tickets :is-loaded :color-filters @book="book" @unbook="unbook" />

    <template #sidebar>
      <ticket-list :tickets="tickets" :booked="booked" :is-loaded @unbook="unbook" @checkout="goToCheckout" />
    </template>

    <template #cartButton>
      <cart-button :tickets="tickets" :booked="booked" />
    </template>
  </layout>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue';
import Layout from './Layout.vue';
import SeatSelect from '../components/SeatSelect.vue';
import TicketList from '../components/TicketList.vue';
import CartButton from '../components/CartButton.vue';
import ColorPalette from '../components/ColorPalette.vue';
import { useTickets } from '../composables/useTickets';
import { useRouter } from 'vue-router';

const { tickets, booked, priceGroups, isLoaded, stopPolling, book, unbook } = useTickets();
const router = useRouter();

const colorFilters = ref({});

onBeforeUnmount(stopPolling);

function goToCheckout() {
  router.push('/checkout');
}
</script>
