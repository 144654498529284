<template>
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-2xl font-semibold text-accent-light">404</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Сторінку не знайдено</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">На жаль, нам не вдалося знайти сторінку, яку ви шукаєте.</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a href="/" class="rounded-md bg-accent-light px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-light/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Додому</a>
        <a href="mailto:info@novoshow.site" class="text-sm font-semibold text-accent-dark">Служба підтримки <span aria-hidden="true">&rarr;</span></a>
      </div>
    </div>
  </main>
</template>
