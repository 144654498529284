<template>
  <layout>
    <div class="w-full h-full flex justify-center items-center">
      <div class="container">
        <form v-if="currentState === STATE_EMAIL" class="space-y-4 w-full max-w-80 mx-auto" @submit="submitEmail">
          <div class="text-sm text-accent-light -mt-10 mb-10">
            Будь ласка, введіть Ваш e-mail для підтвердження особи та отримання квитків.
          </div>

          <alert-error v-if="isError" title="Сталася помилка" :errors="[ 'Перевірте правильність введеного email' ]" />

          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-black/90 -mb-2">Email</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <ph-envelope class="h-5 w-5 text-gray" aria-hidden="true" />
              </div>
              <input id="email" v-model.trim="email" type="email" placeholder="you@example.com" class="block w-full rounded-md border-0 py-1.5 pl-10 text-black/90 ring-1 ring-inset ring-gray/30 placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
            </div>
          </div>

          <div>
            <button type="submit" class="btn-primary w-full" :disabled="!isEmailValid">Продовжити</button>
          </div>
        </form>

        <form v-if="currentState === STATE_PIN_CODE" class="space-y-4 w-full max-w-80 mx-auto" autocomplete="off" @submit="submitPinCode">
          <div class="text-sm text-accent-light -mt-10 mb-10">
            На Ваш e-mail було відправлено пін-код для підтвердження. Будь ласка, введіть його нижче. Якщо ви не бачите лист у вхідних повідомленнях, перевірте папку «Спам».
          </div>

          <alert-error v-if="isError" title="Сталася помилка" :errors="[ 'Перевірте правильність введеного пін-кода' ]" />

          <div>
            <label for="pinCode" class="block text-sm font-medium leading-6 text-black/90 -mb-2">Пін-код</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <ph-key class="h-5 w-5 text-gray" aria-hidden="true" />
              </div>
              <input id="pinCode" v-model.trim="pinCode" type="text" placeholder="XXXX" class="block w-full rounded-md border-0 py-1.5 pl-10 text-black/90 ring-1 ring-inset ring-gray/30 placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
            </div>
          </div>

          <div>
            <button type="submit" class="btn-primary w-full" :disabled="!isPinCodeValid">Продовжити</button>
          </div>
        </form>

        <form v-if="currentState === STATE_NAME" class="space-y-4 w-full max-w-80 mx-auto" @submit="submitUserData">
          <div class="text-sm text-accent-light -mt-10 mb-10">
            Будь ласка, введіть Ваші ПІБ та мобільний телефон. Ця інформація потрібна нам для ідентифікації під час звернення до служби підтримки.
          </div>

          <alert-error v-if="isError" title="Сталася помилка" :errors="[ '' ]" />

          <div>
            <label for="name" class="block text-sm font-medium leading-6 text-black/90 -mb-2">П. І. Б.</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <ph-user class="h-5 w-5 text-gray" aria-hidden="true" />
              </div>
              <input id="name" v-model.trim="name" type="text" placeholder="Івасик Телесик" class="block w-full rounded-md border-0 py-1.5 pl-10 text-black/90 ring-1 ring-inset ring-gray/30 placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
            </div>
          </div>

          <div>
            <label for="phone" class="block text-sm font-medium leading-6 text-black/90 -mb-2">Телефон</label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <ph-phone class="h-5 w-5 text-gray" aria-hidden="true" />
              </div>
              <input id="phone" v-model="phone" type="text" placeholder="+380123456789" class="block w-full rounded-md border-0 py-1.5 pl-10 text-black/90 ring-1 ring-inset ring-gray/30 placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
            </div>
          </div>

          <div>
            <button type="submit" class="btn-primary w-full mt-5" :disabled="!isUserdataValid">Продовжити</button>
          </div>
        </form>

        <div v-if="currentState === STATE_CHECKOUT" class="space-y-4 w-full max-w-80 mx-auto">
          <alert-error v-if="isError" title="Сталася помилка" :errors="[ '' ]" />

          <div>
            <h2 class="text-2xl font-bold text-accent-dark text-center">Готуємо інвойс...</h2>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import Layout from './Layout.vue';
import AlertError from '../components/AlertError.vue';
import { useRouter } from 'vue-router';
import { useTickets } from '../composables/useTickets';
import { useFetch, useStorage } from '@vueuse/core';
import { PhEnvelope, PhKey, PhUser, PhPhone } from '@phosphor-icons/vue';

const STATE_EMAIL = 1;
const STATE_PIN_CODE = 2;
const STATE_NAME = 3;
const STATE_CHECKOUT = 4;

const currentState = ref(STATE_EMAIL);
const isError = ref(false);

const router = useRouter();
const { booked, stopPolling } = useTickets();

if (!booked.value.length) {
  router.push('/book');
}

onBeforeUnmount(stopPolling);

const email = useStorage('email', null);
const isEmailValid = computed(() => email.value && email.value.match(/^.+@.+\..+$/));

const pinCode = ref('');
const isPinCodeValid = computed(() => pinCode.value && pinCode.value.match(/^\d{4}$/));

const name = ref('');
const phone = ref('');
const isUserdataValid = computed(() => name.value && phone.value);

const lastInvoiceId = useStorage('lastInvoiceId', null);

async function submitEmail(event) {
  event.preventDefault();

  if (!isEmailValid.value) {
    return;
  }

  isError.value = false;
  currentState.value = STATE_PIN_CODE;

  const { error } = await useFetch(`/api/e-mail/${email.value}/send-pin`).get().json();
  if (error.value) {
    currentState.value = STATE_EMAIL;
    isError.value = true;
  }
}

async function submitPinCode(event) {
  event.preventDefault();

  if (!isPinCodeValid.value) {
    return;
  }

  isError.value = false;
  currentState.value = STATE_NAME;

  const { data } = await useFetch(`/api/e-mail/${email.value}/validate-pin/${pinCode.value}`).get().text();
  if (data.value !== 'true') {
    currentState.value = STATE_PIN_CODE;
    isError.value = true;
  }
}

async function submitUserData(event) {
  event.preventDefault();

  if (!isUserdataValid.value) {
    return;
  }

  isError.value = false;
  currentState.value = STATE_CHECKOUT;

  const { data } = await useFetch('/api/purchase/checkout-create/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ticketIds: booked.value,
      email: email.value,
      fullName: name.value,
      phoneNumber: phone.value
    })
  }).json();

  if (!data.value?.invoiceId || !data.value?.pageUrl) {
    isError.value = true;
    return;
  }

  lastInvoiceId.value = data.value.invoiceId;
  window.location.href = data.value.pageUrl;
}
</script>
