<template>
  <div class="h-full">
    <div class="h-full flex flex-col" :class="{ 'lg:pr-80 xl:pr-96': isSidebarEnabled }">
      <main class="grow relative">
        <slot />
      </main>

      <div v-if="isSidebarEnabled" class="lg:hidden shrink-0 z-40 relative" @click="sidebarOpen = true">
        <slot name="cartButton" />
      </div>
      <slot name="footer">
        <the-footer />
      </slot>
    </div>

    <div v-if="isSidebarEnabled" class="hidden lg:fixed lg:top-navbar lg:bottom-0 lg:right-0 lg:z-50 lg:flex lg:w-80 xl:w-96 lg:flex-col lg:mt-px">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-l border-accent-dark/30 bg-white">
        <slot name="sidebar" />
      </div>
    </div>

    <TransitionRoot as="template" :show="isSidebarEnabled && sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black/30" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 flex w-16 justify-center">
                  <button type="button" class="m-2.5 p-2.5 text-white z-10" @click="sidebarOpen = false">
                    <ph-x class="size-6" />
                  </button>
                </div>
              </TransitionChild>
              <div class="absolute top-0 left-0 right-0 bg-[#000000]">
                <div class="flex items-center">
                  <div class="size-16 object-cover overflow-hidden">
                    <img src="/novoshow.jpg" alt="">
                  </div>
                  <div class="grow mx-4 text-accent-light text-sm xs:text-base text-center">
                    Благодійне новорічне шоу «У країні дитячих мрій»
                  </div>
                  <div class="w-16" />
                </div>
              </div>
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white pt-20 xs:px-6 pb-4">
                <slot name="sidebar" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, useSlots } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import { PhX } from '@phosphor-icons/vue';
import TheFooter from '../components/TheFooter.vue';

const sidebarOpen = ref(false);

const slots = useSlots();

const isSidebarEnabled = Boolean(slots.sidebar);
</script>
