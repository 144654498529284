<template>
  <div class="container h-0 min-h-full relative">
    <div class="min-h-full flex flex-col items-center justify-center">
      <div v-if="isLoading" class="flex flex-col items-center justify-center space-y-5 w-full max-w-md">
        <the-logo class="w-3/4 max-w-56 animate-pulse" />
        Loading...
      </div>

      <div v-else-if="error" class="flex flex-col items-center justify-center space-y-5 w-full max-w-md">
        <alert-error v-if="error" title="Сталася помилка" :errors="[ error ]" class="w-full" />

        <button class="btn-outline w-full" @click="reload">Спробувати знову</button>
      </div>

      <div v-else-if="ticket" class="flex flex-col items-center justify-center space-y-5 w-full max-w-md">
        <ticket-details :ticket="ticket" class="w-full" />

        <button class="btn-primary w-full" @click="closeTicketDetails">Cканувати знову</button>
      </div>

      <div v-else class="grow flex flex-col items-center justify-start space-y-5 py-5 w-full max-w-md">
        <button v-if="isCameraOn" class="btn-outline w-full" @click="stopScan">Припинити сканування</button>
        <button v-else class="btn-primary w-full" @click="startScan">Почати сканування</button>

        <div class="size-full sm:size-1/2" :class="isInitialized && isCameraOn ? null : 'invisible'">
          <qrcode-stream
            :paused="!isCameraOn"
            :formats="['qr_code']"
            :constraints="{ facingMode: 'environment' }"
            :track="paintOutline"
            @detect="onDetect"
            @camera-on="onInit"
            @error="onError"
          />
        </div>

        <button v-show="isCandidateUrlValid" class="btn-primary w-full" @click="check">Перевірити</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { QrcodeStream } from 'vue-qrcode-reader';
import { onBeforeRouteLeave } from 'vue-router';
import { useFetch } from '@vueuse/core';
import AlertError from '../components/AlertError.vue';
import TicketDetails from '../components/TicketDetails.vue';
import TheLogo from '../components/TheLogo.vue';

const error = ref(null);
const isLoading = ref(false);
const isInitialized = ref(false);
const isCameraOn = ref(false);
const candidateUrl = ref(null);
const ticket = ref(null);

onBeforeRouteLeave(() => false);

const isCandidateUrlValid = computed(() => String(candidateUrl.value ?? '').startsWith('https://novoshow.site/verify/'));

function reload() {
  if (isInitialized.value) {
    error.value = null;
    startScan();
    return;
  }

  window.location.reload();
}

function onInit() {
  isInitialized.value = true;
}

function onError(_error) {
  if (_error.message === 'Permission denied') {
    error.value = 'Please allow camera access';
    return;
  }

  error.value = _error?.message;
}

function onDetect(detectedCodes) {
  candidateUrl.value = detectedCodes[0]?.rawValue;
}

function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints;

    ctx.strokeStyle = 'green';

    ctx.beginPath();
    ctx.moveTo(firstPoint.x, firstPoint.y);
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y);
    }
    ctx.lineTo(firstPoint.x, firstPoint.y);
    ctx.closePath();
    ctx.stroke();
  }
}

async function check() {
  if (!isCandidateUrlValid.value) {
    return;
  }

  ticket.value = null;
  isLoading.value = true;

  const url = candidateUrl.value.replace('https://novoshow.site/', '/api/');

  const { data, error: _error, statusCode } = await useFetch(url).get().json();

  isLoading.value = false;

  if (statusCode.value === 400) {
    error.value = 'Квиток не знайдено';
    return;
  }

  if (_error.value) {
    error.value = _error.value;
    return;
  }

  ticket.value = data.value;
}

function startScan() {
  candidateUrl.value = null;
  isCameraOn.value = true;
}

function stopScan() {
  candidateUrl.value = null;
  isCameraOn.value = false;
}

function closeTicketDetails() {
  ticket.value = null;
  startScan();
}
</script>
