<template>
  <div class="flex w-full items-center p-2 space-x-2 border border-accent-dark/30 rounded shadow bg-accent-light/10">
    <ph-ticket class="-my-2 size-8 xl:size-10 text-accent-dark/20" weight="thin" />

    <div class="grow text-accent-dark font-semibold">
      <div class="text-sm">
        <span class="opacity-70 font-normal">ряд</span>
        {{ ticket.rowNumber }}
        <span class="opacity-70 font-normal ml-2">місце</span>
        {{ ticket.placeNumber }}
      </div>
    </div>
  </div>

  <alert-error v-if="!ticket.isFirstVerify" title="Квиток вже використано!" :errors="[ `Квиток було зарееєстровано о ${timeHr}` ]" class="w-full" />
</template>

<script setup>
import { computed } from 'vue';
import { PhTicket } from '@phosphor-icons/vue';
import AlertError from './AlertError.vue';
import dayjs from 'dayjs';

const props = defineProps({
  ticket: {
    type: Object,
    required: true
  }
});

const timeHr = computed(() => dayjs(props.ticket?.verifiedAt).format('HH:mm'));
</script>
