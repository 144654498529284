<template>
  <div class="flex items-center p-2 space-x-2 border border-accent-dark/30 rounded shadow bg-accent-light/10">
    <ph-ticket class="-my-2 size-8 xl:size-10 text-accent-dark/20" weight="thin" />

    <div class="grow text-accent-dark font-semibold">
      <div class="text-sm">
        <span class="opacity-70 font-normal">ряд</span>
        {{ ticket.rowNumber }}
        <span class="opacity-70 font-normal ml-2">місце</span>
        {{ ticket.placeNumber }}
      </div>
    </div>

    <div class="shrink-0 text-accent-dark font-semibold">
      {{ priceHr }}
      <span class="opacity-70 font-normal">грн</span>
    </div>

    <button class="text-primary/80 hover:text-primary" @click="emit('unbook')">
      <ph-trash class="size-6 ml-2" weight="bold" />
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PhTicket, PhTrash } from '@phosphor-icons/vue';

const props = defineProps({
  ticket: {
    type: Object,
    required: true
  }
});

const emit = defineEmits([ 'unbook' ]);

const priceHr = computed(() => (props.ticket?.priceGroup?.priceInCents || 0) / 100);
</script>
