<template>
  <footer class="shrink-0">
    <div class="text-accent-dark mx-auto max-w-7xl py-4 lg:py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 border-t border-accent-dark/30">
      <social-links class="flex justify-center space-x-6 md:order-2" />

      <div class="mt-2 md:order-1 md:mt-0">
        <p class="text-center text-base opacity-60">
          <span>&copy; {{ year }}</span>

          <span class="mx-5 border-accent-dark/60 border-l" />

          Made in <span class="text-lg mx-1 relative top-[2px]">🇺🇦</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import SocialLinks from './SocialLinks.vue';

const year = new Date().getFullYear();
</script>
