import { ref, computed } from 'vue';
import { useFetch, useStorage } from '@vueuse/core';

const TICKET_BOOK_DURATION_MS = 15 * 60 * 1000; // 15 minutes // FIXME: get from backend
const POLLING_INTERVAL_MS = 10 * 1000; // 10 seconds

const tickets = ref({});
const priceGroups = ref({});
const isLoaded = ref(false);
const bookedWithExpiration = useStorage('vbooked', {});
let refetchTickets = null;

const booked = computed(() => Object.keys(bookedWithExpiration.value).filter(id => bookedWithExpiration.value[id] > Date.now()));

let timeout = null;

function stopPolling() {
  clearTimeout(timeout);
  isLoaded.value = false;
}

async function book(id) {
  if (!id) {
    return;
  }

  const { error } = await useFetch(`/api/ticket/${id}/book/`).post().json();
  if (!error?.value) {
    bookedWithExpiration.value[id] = Date.now() + TICKET_BOOK_DURATION_MS;
  }
  await refetch();
}

async function unbook(id) {
  if (!id) {
    return;
  }

  const { error } = await useFetch(`/api/ticket/${id}/unbook/`).post().json();
  if (!error?.value) {
    delete bookedWithExpiration.value[id];
  }

  await refetch();
}

async function refetch() {
  clearTimeout(timeout);
  return await refetchTickets();
}

async function checkout(ticketIds) {
  return await useFetch('/api/ticket/checkout-create/').post({ ticketIds }).json();
}

export function useTickets(pollingIntervalMs = POLLING_INTERVAL_MS) {
  if (!isLoaded.value) {
    const { execute } = useFetch('/api/ticket/', {
      afterFetch(ctx) {
        if (!ctx?.data?.tickets?.length) {
          return ctx;
        }

        const _tickets = {};
        const _priceGroups = {};
        ctx.data.tickets.forEach(ticket => {
          _tickets[ticket.id] = ticket;
          if (ticket.priceGroup.id) {
            _priceGroups[ticket.priceGroup.id] = ticket.priceGroup.priceInCents;
          }

          if (bookedWithExpiration.value[ticket.id]) {
            if (bookedWithExpiration.value[ticket.id] < Date.now()) {
              delete bookedWithExpiration.value[ticket.id];
            }

            if (ticket.status !== 'booked') {
              delete bookedWithExpiration.value[ticket.id];
            }
          }
        });

        tickets.value = _tickets;
        priceGroups.value = _priceGroups;
        isLoaded.value = true;

        timeout = setTimeout(execute, pollingIntervalMs);

        return ctx;
      }
    }).get().json();

    refetchTickets = execute;
  }

  return { tickets, booked, priceGroups, isLoaded, stopPolling, book, unbook, checkout, refetch };
}
