import { ViteSSG } from 'vite-ssg';
import App from './App.vue';
import routes from './routes';
import { MotionPlugin } from '@vueuse/motion';
import './assets/style/main.scss';

export const createApp = ViteSSG(
  App,
  { routes },
  ({ app }) => {
    // install plugins
    app.use(MotionPlugin);
  }
);
