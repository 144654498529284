<template>
  <div ref="container" class="absolute inset-0">
    <PinchScrollZoom
      :key="width + '_' + height"
      ref="zoomer"
      within
      centered
      translate3d
      :width="width"
      :height="height"
      :scale="minScale"
      :min-scale="minScale"
      :max-scale="maxScale"
      :content-width="contentWidth"
      :content-height="contentHeight"
    >
      <slot />
    </PinchScrollZoom>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';

const props = defineProps({
  contentWidth: {
    type: Number,
    required: true
  },
  contentHeight: {
    type: Number,
    required: true
  }
});

const zoomer = ref(null);
const container = ref(null);
const { width, height } = useElementSize(container);

const minScale = computed(() => Math.min(width.value / props.contentWidth, height.value / props.contentHeight));
const maxScale = computed(() => Math.min(width.value / 1000, height.value / 1000));
</script>
