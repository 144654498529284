import Home from './pages/Home.vue';
import Book from './pages/Book.vue';
import Checkout from './pages/Checkout.vue';
import PaymentStatus from './pages/PaymentStatus.vue';
import PassControl from './pages/PassControl.vue';
import NotFound from './pages/NotFound.vue';

export default [
  {
    path: '/',
    component: Home
  },

  {
    path: '/book',
    component: Book
  },

  {
    path: '/checkout',
    component: Checkout
  },

  {
    path: '/payment-status',
    component: PaymentStatus
  },

  {
    path: '/pass',
    component: PassControl
  },

  {
    path: '/404',
    component: NotFound
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];
