<template>
  <Popover class="absolute top-4 left-4 z-10">
    <button class="btn bg-[#e2d5b1] hover:bg-[#e9dfc3] text-accent-dark font-normal space-x-2 pl-2 pr-4 py-1 border-accent-dark/40 w-44" @click="isOpen = true">
      <PhFunnel class="w-6 h-6" />
      <div class="text-base text-nowrap">фільтр за ціною</div>
    </button>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <template v-if="isOpen">
        <PopoverPanel class="absolute -top-5 -left-5 z-10 -mt-px -ml-px" static>
          <div class="absolute top-4 left-4 z-50 border border-accent-dark/40 bg-white rounded-lg">
            <div class="p-1 border-b border-accent-dark/40">
              <button class="btn bg-[#e2d5b1] hover:bg-[#e9dfc3] text-accent-dark font-normal space-x-2 pl-2 pr-4 py-1 border-accent-dark/40 flex-nowrap w-44" @click="isOpen = false">
                <PhFunnelX class="w-6 h-6" />
                <div class="text-base text-nowrap">згорнути фільтр</div>
              </button>
            </div>

            <div class="flex flex-col space-y-2 m-4">
              <template v-for="(price, id) in priceGroups" :key="id">
                <color-toggle v-model="selected[id]" :color="Colors[id]">{{ priceHr(price) }}</color-toggle>
              </template>
            </div>

            <div class="border-t border-accent-dark/40 p-4">
              <div class="flex items-center space-x-2">
                <seat-icon class="size-6 text-rose-400" />
                <div class="text-sm text-accent-dark/90">- вибрано</div>
              </div>

              <div class="flex items-center space-x-2">
                <seat-icon class="size-6 text-gray/25" />
                <div class="text-sm text-accent-dark/90">- не доступно</div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </template>
    </transition>
  </Popover>
</template>

<script setup>
import { ref } from 'vue';
import Colors from '../colors';
import ColorToggle from './ColorToggle.vue';
import SeatIcon from './SeatIcon.vue';
import { Popover, PopoverPanel } from '@headlessui/vue';
import { PhFunnel, PhFunnelX } from '@phosphor-icons/vue';

defineProps({
  priceGroups: {
    type: Object,
    default: () => ({})
  }
});

const isOpen = ref(true);

const selected = defineModel({ type: Object, default: () => ({}) });

function priceHr(price) {
  return price ? `${price / 100} грн` : null;
}
</script>
