<template>
  <pinch-scroll-zoom :content-width="svgWidth" :content-height="svgHeight">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      :width="`${svgWidth}px`"
      :height="`${svgHeight}px`"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:ev="http://www.w3.org/2001/xml-events"
    >
      <!-- Border -->
      <rect :x="CELL_SIZE" :y="CELL_SIZE" :width="svgWidth - 2*CELL_SIZE" :height="svgHeight - 2*CELL_SIZE" fill="#fff" stroke="#ccc" stroke-width="4" :rx="CELL_SIZE/4" :ry="CELL_SIZE/4" />

      <!-- Stage -->
      <g :transform="`translate(${(svgWidth - 2280)/2.5}, ${CELL_SIZE}) scale(2.5) `" class="text-gray/20">
        <g fill="currentColor">
          <path d="M0,0 L1133,0 C1133,38.1076477 1102.10765,69 1064,69 L69,69 C30.8923523,69 4.66684088e-15,38.1076477 0,0 L0,0 L0,0 Z" />
        </g>
        <text font-size="40" font-weight="bold" fill="#FFFFFF" x="481" y="48">СЦЕНА</text>
      </g>

      <!-- Pit -->
      <g :transform="`translate(${svgWidth/2 - CELL_SIZE * 2.3}, ${CELL_SIZE * 7.3})`">
        <rect x="0" y="0" :width="CELL_SIZE * 4.6" :height="CELL_SIZE * 1.8" :rx="CELL_SIZE/4" :ry="CELL_SIZE/4" stroke-width="4" stroke="#ccc" fill="#eeeeee99" />
      </g>

      <template v-for="row in ROWS" :key="row">
        <!-- Rows -->
        <g :transform="`translate( ${CELL_SIZE * 2}, ${CELL_SIZE * (row + 3)})`" class="text-black font-bold">
          <text :x="CELL_SIZE * 0.25" :y="CELL_SIZE / 2" fill="currentColor" :font-size="FONT_SIZE * 1.4" text-anchor="middle" alignment-baseline="middle">
            {{ row }}
          </text>
        </g>

        <!-- Rows -->
        <g :transform="`translate( ${(COLS + 3) * CELL_SIZE}, ${CELL_SIZE * (row + 3)})`" class="text-black font-bold">
          <text :x="CELL_SIZE * 0.75" :y="CELL_SIZE / 2" fill="currentColor" :font-size="FONT_SIZE * 1.4" text-anchor="middle" alignment-baseline="middle">
            {{ row }}
          </text>
        </g>

        <!-- Seats -->
        <template v-for="col in COLS" :key="col">
          <g
            v-if="seatNumber(row, col)"
            :transform="`translate( ${CELL_SIZE * (col + 2)}, ${CELL_SIZE * (row + 3)})`"
          >
            <g
              class="cursor-pointer font-extralight hover:scale-125 hover:translate-x-[-15px] hover:translate-y-[-15px] transition-all"
              :class="classByRowCol(row, col, booked)"
              @click="toggleBook(row, col)"
            >
              <seat-item :transform="`translate(${(CELL_SIZE - 100) / 2}, ${(CELL_SIZE - 100) / 2})`" />
              <text :x="CELL_SIZE / 2" :y="CELL_SIZE * 0.47" fill="black" :font-size="FONT_SIZE" text-anchor="middle" alignment-baseline="middle">
                {{ seatNumber(row, col) }}
              </text>
            </g>
          </g>
        </template>
      </template>
    </svg>
  </pinch-scroll-zoom>
</template>

<script setup>
import { computed, toRaw } from 'vue';
import PinchScrollZoom from './PinchScrollZoom.vue';
import SeatItem from './SeatItem.vue';
import Colors from '../colors';

// NOTE: rows and cols here not the same as real rowNumber and seatNumber. Maybe it's better to rename them in future.

const ROWS = 16;
const COLS = 33;
const CELL_SIZE = 120;
const FONT_SIZE = 40;

const svgWidth = computed(() => (COLS + 6) * CELL_SIZE);
const svgHeight = computed(() => (ROWS + 6) * CELL_SIZE);

const props = defineProps({
  tickets: {
    type: Object,
    default: () => ({})
  },
  booked: {
    type: Array,
    default: () => []
  },
  colorFilters: {
    type: Object,
    default: () => ({})
  },
  isLoaded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([ 'book', 'unbook' ]);

const bookedByRowCol = computed(() => props.booked.map(id => {
  const ticket = props.tickets[id];
  return `${ticket.rowNumber}_${ticket.placeNumber}`;
}));

const ticketsByRealRowSeatNumber = computed(() => {
  const _tickets = {};

  Object.values(toRaw(props.tickets)).forEach(ticket => {
    _tickets[ticket.rowNumber + '_' + ticket.placeNumber] = ticket;
  });
  return _tickets;
});

const isAnyFilterSelected = computed(() => Object.values(props.colorFilters).some(v => v));

function classByRowCol(row, col) {
  if (!props.isLoaded) {
    return 'text-gray/40 opacity-40 pointer-events-none';
  }

  if (isBooked(row, col)) {
    return 'text-rose-400';
  }

  const ticket = ticketsByRealRowSeatNumber.value[rowColToString(row, col)];
  if (!ticket) {
    console.log('ticket not found', row, col);
    return 'text-gray/40';
  }

  if (ticket.status !== 'free') {
    return 'text-gray/40 opacity-40 pointer-events-none scale-75 translate-x-[15px] translate-y-[15px]';
  }

  if (ticket.priceGroup?.id) {
    if (isAnyFilterSelected.value && !props.colorFilters[ticket.priceGroup.id]) {
      return `text-[${Colors[ticket.priceGroup.id]}] opacity-40 brightness-70`;
    }
    return `text-[${Colors[ticket.priceGroup.id]}]`;
  }

  return 'text-gray/40';
}

function seatNumber(row, col) {
  const seatNum = COLS - col + 1;
  if (row === 1) {
    if (seatNum > 25) {
      return seatNum - 17;
    }

    if (seatNum > 8) {
      return null;
    }

    return seatNum;
  }

  if (row === 4) {
    if (seatNum > 19) {
      return seatNum - 5;
    }

    if (seatNum > 14) {
      return null;
    }

    return seatNum;
  }

  if (row === 5) {
    if (seatNum > 19) {
      return seatNum - 5;
    }

    if (seatNum > 14) {
      return null;
    }

    return seatNum;
  }

  if (row === 6) {
    if (seatNum > 14 && seatNum < 20) {
      return null;
    }

    return seatNum;
  }

  return seatNum;
}

function rowColToString(row, col) {
  return row + '_' + seatNumber(row, col);
}

function isBooked(row, col) {
  return bookedByRowCol.value.includes(rowColToString(row, col));
}

function toggleBook(row, col) {
  const id = ticketsByRealRowSeatNumber.value[rowColToString(row, col)]?.id;
  if (!id) {
    return;
  }

  emit(isBooked(row, col) ? 'unbook' : 'book', id);
}
</script>
