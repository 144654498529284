<template>
  <div class="absolute bottom-0 inset-x-0">
    <button
      v-if="booked.length"
      type="button"
      class="btn-primary flex text mx-auto space-x-8 mb-2 pl-4"
    >
      <ph-shopping-cart class="size-6 opacity-60" />
      <div>{{ hrBookedLength }}</div>
      <div>{{ bookedPrice }}</div>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PhShoppingCart } from '@phosphor-icons/vue';

const props = defineProps({
  tickets: {
    type: Object,
    default: () => ({})
  },
  booked: {
    type: Array,
    default: () => []
  }
});

const hrBookedLength = computed(() => {
  const count = props.booked?.length || 0;
  if (count === 1) {
    return '1 квиток';
  }

  if (count > 1 && count < 5) {
    return `${count} квитки`;
  }

  return `${count} квитків`;
});

const bookedPrice = computed(() => {
  const price = props.booked.reduce((acc, id) => acc + (props.tickets[id]?.priceGroup?.priceInCents || 0), 0);
  return price ? `${price * 107 / 10000} грн` : null;
});
</script>
